import React from 'react';
import ReactPlayer from 'react-player';
import Layout from "../../../components/layout";

class Videos extends React.Component {
    render() {
        return (
            <Layout>
                <div id="fb-root"></div>
                <script async defer src="https://connect.facebook.net/en_US/sdk.js"></script>

                <h1 id="tech">Technical tips</h1>
                <h2>Introduction</h2>
                <div>
                    <ReactPlayer url="https://www.facebook.com/video.php?v=830923006949454" height="500px"/>
                </div>
                <h2>Jodan Uke</h2>
                <div>
                    <ReactPlayer url="https://www.facebook.com/video.php?v=830928766948878" height="500px"/>
                </div>

                <h2>Kakie</h2>
                <div>
                    <ReactPlayer url="https://www.facebook.com/video.php?v=830936193614802" height="500px"/>
                </div>
                <h2>Ude Tanren</h2>
                <div>
                    <ReactPlayer url="https://www.facebook.com/video.php?v=830939420281146" height="500px"/>
                </div>
                <h2>More Ude Tanren</h2>
                <div>
                    <ReactPlayer url="https://www.youtube.com/embed/gEF-fdVGwaA" height="500px"/>
                </div>
                <h2 class="p1"><span class="s1">Nunchaku Kata</span></h2>
                <div>
                    <ReactPlayer url="https://www.facebook.com/video.php?v=831374616904293" height="500px"/>
                </div>
                <hr/>
                <h1 id="misc">Miscellaneous and fun videos</h1>
                <div>
                    <ReactPlayer url="https://www.facebook.com/video.php?v=830950893613332" height="500px"/>
                </div>
                <div>
                    <ReactPlayer url="https://www.facebook.com/video.php?v=831499356891819" height="500px"/>
                </div>


            </Layout>);
    }
}

export default Videos;
